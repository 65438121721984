import React, { FC } from 'react';

import {
  Box,
  CardContent,
  ClickAwayListener,
  Collapse,
  Divider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SearchEngineEnum } from 'common/enums';
import DocNoteCreate from 'containers/DocNotes/DocNoteCreate';
import DocNotesResults from 'containers/DocNotes/DocNotesResults';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

interface Props {
  data: RetrievalUnitData;
  notesExpanded: boolean;
  searchEngine?: SearchEngineEnum;
  setNotesExpanded: (value: boolean) => void;
}

export const Expand: FC<Props> = ({
  data,
  notesExpanded,
  searchEngine,
  setNotesExpanded,
}) => {
  const { t } = useTranslation('common');

  const { organizeDocId } = data;

  const handleNotesAccordionClickAway = () => {
    if (notesExpanded) {
      setNotesExpanded(false);
    }
  };

  return (
    <Collapse
      in={notesExpanded}
      sx={{ bgcolor: 'grey.100' }}
      timeout="auto"
      unmountOnExit
    >
      <Divider />

      <ClickAwayListener onClickAway={handleNotesAccordionClickAway}>
        <CardContent>
          <Typography variant="h5">{t('retrievalUnit.notes.title')}</Typography>

          <Box my={2}>
            <DocNoteCreate
              docData={data}
              docId={organizeDocId}
              searchEngine={searchEngine}
            />
          </Box>

          <DocNotesResults
            docId={data.document.id}
            organizeDocId={organizeDocId}
          />
        </CardContent>
      </ClickAwayListener>
    </Collapse>
  );
};
