import React, { FC } from 'react';

import { CardContent, Chip, Grid, Typography } from '@mui/material';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { AuthorsList } from 'common/components/AuthorsList/AuthorsList';
import DocCites from 'common/components/Docs/DocCites';
import DocDate from 'common/components/Docs/DocDate/DocDate';
import DocMetadata from 'common/components/Docs/DocMetadata/DocMetadata';
import DocRefs from 'common/components/Docs/DocRefs';
import { HitType, SearchEngineEnum } from 'common/enums';
import { DocSourcesEnum } from 'common/utils/documents';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import DocAbstract from '../DocAbstract';
import { DocImage } from '../DocImage/DocImage';
import { DocInlineTags } from '../DocInlineTags';
import DocSource from '../DocSource';
import DocStatus from '../DocStatus/DocStatus';
import { isRetrievalUnitData } from '../isRetrievalUnitData';
import { RetrievalUnitExplanation } from '../RetrievalUnitExplanation/RetrievalUnitExplanation';
import { RetrievalUnitGithub } from '../RetrievalUnitGithub/RetrievalUnitGithub';
import { RetrievalUnitTweets } from '../RetrievalUnitTweets/RetrievalUnitTweets';
import { useStyles } from '../styles';

import { Title } from './Title';

const DOCUMENT_TYPES: string[] = [
  HitType.Document,
  HitType.PrivateDocument,
  HitType.ExternalDocument,
];

export interface ContentProps {
  data: Partial<RetrievalUnitData>;
  evidenceNumber?: number;
  explanation?: string;
  mapDocIndex?: number;
  pageNumber?: number;
  resultType?: ResultType;
  searchEngine?: SearchEngineEnum;
  searchId?: string;
  suggestedTags?: TagDetailsBase[];
  withImage?: boolean;
}

export const Content: FC<ContentProps> = ({
  data,
  evidenceNumber,
  explanation,
  mapDocIndex,
  pageNumber,
  resultType,
  searchEngine,
  searchId,
  suggestedTags,
  withImage,
}) => {
  const classes = useStyles();

  const {
    abstractContent,
    authors,
    document,
    getCitesId = null,
    getRefsId = null,
    numberOfCitations,
    numberOfRefs,
    representations,
    source,
    status,
  } = data;
  const docId = document?.id;

  const docAbstract =
    source === DocSourcesEnum.GitHub ? representations?.text : abstractContent;

  const isDocumentType = document?.type
    ? DOCUMENT_TYPES.includes(document.type)
    : null;
  const renderCites = typeof numberOfCitations === 'number';
  const renderRefs = typeof numberOfRefs === 'number';

  const isFullData = isRetrievalUnitData(data);

  return (
    <Grid p={1} spacing={0} container>
      {withImage && isFullData && <DocImage data={data} />}
      <Grid p={0} item xs>
        <CardContent className={classes.cardContent}>
          {explanation && (
            <RetrievalUnitExplanation explanation={explanation} />
          )}
          {status && <DocStatus status={status} />}
          <div>
            {evidenceNumber && (
              <Typography component="span" pr={1}>
                {evidenceNumber}.
              </Typography>
            )}
            {mapDocIndex && pageNumber === 1 && (
              <Typography component="span" pr={1}>
                {mapDocIndex}.
              </Typography>
            )}
            {isFullData && docId && (
              <DocSource
                duplicates={data.duplicates}
                id={docId}
                resultType={resultType}
                searchId={searchId}
                source={data.source}
                uri={data.uri}
              />
            )}
            <Title
              data={data}
              resultType={resultType}
              searchEngine={searchEngine}
              searchId={searchId}
            />
          </div>

          {isFullData && (
            <DocMetadata>
              <DocDate date={data.date} year={data.year} />
              {authors && <AuthorsList authors={authors} />}
            </DocMetadata>
          )}

          {isFullData && !isDocumentType && document?.type && (
            <Chip label={document.type} size="small" />
          )}

          {docAbstract && (
            <DocAbstract
              abstractContent={docAbstract}
              highlight={data.highlight}
              id={docId}
              resultType={resultType}
              searchId={searchId}
            />
          )}

          <div className={classes.additionalMetadata}>
            {renderCites && numberOfCitations > 0 && (
              <DocCites getCitesId={getCitesId} score={numberOfCitations} />
            )}

            {renderRefs && numberOfRefs > 0 && (
              <DocRefs getRefsId={getRefsId} score={numberOfRefs} />
            )}

            {docId && data.tweets && (
              <RetrievalUnitTweets
                documentId={docId}
                numberOfTweets={data.numberOfTweets}
                resultType={resultType}
                searchId={searchId}
                tweets={data.tweets}
              />
            )}

            {docId &&
              data.githubRepos &&
              typeof data.githubScore === 'number' && (
                <RetrievalUnitGithub
                  documentId={docId}
                  repos={data.githubRepos}
                  resultType={resultType}
                  score={data.githubScore}
                  searchId={searchId}
                />
              )}
          </div>
          {isFullData && (
            <DocInlineTags
              docData={data}
              searchEngine={searchEngine}
              suggestedTags={suggestedTags}
            />
          )}
        </CardContent>
      </Grid>
    </Grid>
  );
};
