import React, { FC } from 'react';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import DocTitle from 'common/components/Docs/DocTitle/DocTitle';
import { SearchEngineEnum } from 'common/enums';

import DocTitleWithExternalPDF from '../DocTitleWithExternalPDF';
import DocTitleWithPDF from '../DocTitleWithPDF';
import { isRetrievalUnitData } from '../isRetrievalUnitData';
import { RetrievalUnitData } from '../RetrievalUnitData.interface';

interface Props {
  data: Partial<RetrievalUnitData>;
  resultType?: ResultType;
  searchEngine?: SearchEngineEnum;
  searchId?: string;
}

export const Title: FC<Props> = ({
  data,
  resultType,
  searchEngine,
  searchId,
}) => {
  const isFullData = isRetrievalUnitData(data);

  const {
    document,
    documentId,
    externalPublicAsset,
    organizeDocId,
    privateAsset,
    publicAsset,
    title,
  } = data;
  const docId = document?.id;

  if (isFullData && docId && title && externalPublicAsset) {
    return (
      <DocTitleWithExternalPDF
        docData={data}
        id={docId}
        resultType={resultType}
        searchEngine={searchEngine}
        searchId={searchId}
        title={title}
      />
    );
  }

  if (
    isFullData &&
    documentId &&
    docId &&
    organizeDocId &&
    title &&
    (publicAsset || privateAsset)
  ) {
    return (
      <DocTitleWithPDF
        documentId={documentId}
        id={docId}
        organizeDocId={organizeDocId}
        resultType={resultType}
        searchId={searchId}
        title={title}
      />
    );
  }

  if (!title) {
    return null;
  }

  return (
    <DocTitle
      id={docId}
      resultType={resultType}
      searchId={searchId}
      title={title}
      uri={data.uri}
    />
  );
};
