import React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { FormSwitchControl } from 'common/components/Fields/FormSwitchControl';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { ShareEnum, ShareType } from 'common/enums';

import { ShareDocumentsOptions } from '../ShareDocuments.interface';

const useStyles = makeStyles(() => ({
  switchLabel: {
    justifyContent: 'space-between',
    marginLeft: 0,
    width: '100%',
  },
}));

interface ShareViaEmailFormValues {
  includeNotes: boolean;
}

export type ShareViaEmailDialogProps = {
  getShareLink: (
    shareType: ShareType,
    options?: ShareDocumentsOptions
  ) => string;
  notesLoading?: boolean;
  onNotesInclude?: () => void;
  onShare?: () => any;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ShareViaEmailDialog = ({
  getShareLink,
  notesLoading,
  onNotesInclude,
  onShare,
  open,
  setOpen,
}: ShareViaEmailDialogProps) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: ShareViaEmailFormValues) => {
    window.open(getShareLink(ShareEnum.Email, { ...values }));

    if (onShare) {
      onShare();
    }

    handleClose();
  };

  const handleValueChange = (e: React.ChangeEvent<any>, checked: boolean) => {
    if (checked && onNotesInclude) {
      onNotesInclude();
    }
  };

  return (
    <Dialog
      aria-label={t('shareViaEmail.dialog.ariaLabel')}
      data-testid="ShareViaEmailDialog"
      maxWidth="sm"
      open={open}
      fullWidth
      onClose={handleClose}
    >
      <DialogActionTitle onClose={handleClose}>
        {t('shareViaEmail.dialog.title')}
      </DialogActionTitle>

      <Formik
        initialValues={{ includeNotes: false }}
        onReset={handleClose}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              <FormControlLabel
                classes={{ root: classes.switchLabel }}
                control={
                  <FormSwitchControl
                    name="includeNotes"
                    onValueChange={handleValueChange}
                  />
                }
                disabled={notesLoading}
                label={
                  <Box alignItems="center" display="flex">
                    <Box mr={1}>{t('shareViaEmail.dialog.switchLabel')}</Box>

                    {notesLoading && (
                      <CircularProgress color="secondary" size={20} />
                    )}
                  </Box>
                }
                labelPlacement="start"
              />
            </DialogContent>

            <DialogActions>
              <Button type="reset">{t('buttons.cancel')}</Button>

              <SubmitButton
                disabled={notesLoading}
                eventName={TrackEventName.EmailShared}
                isSubmitting={isSubmitting}
                text={t('buttons.share')}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

ShareViaEmailDialog.displayName = 'ShareViaEmailDialog';

export default ShareViaEmailDialog;
