import React from 'react';

import { Dialog } from '@mui/material';
import { useSelector } from 'react-redux';

import ExplainHighlightCard from 'containers/ExplainHighlight';
import SearchPopperContent from 'containers/SearchResults/SearchPopper/SearchPopperContent';
import SearchPopperHeader from 'containers/SearchResults/SearchPopper/SearchPopperHeader';
import { selectUser } from 'containers/User/user.slice';

export type SearchWithNoteOrHighlightDialogProps = {
  docId?: string;
  onSearchPopperClose: () => void;
  searchPopperOpen: boolean;
  searchQuery: string;
};

const SearchWithNoteOrHighlightDialog = ({
  docId,
  onSearchPopperClose,
  searchPopperOpen,
  searchQuery,
}: SearchWithNoteOrHighlightDialogProps) => {
  const userSettings = useSelector(selectUser);

  return (
    <Dialog
      aria-label="Search results for given query"
      data-testid="SearchWithNoteOrHighlightDialog"
      maxWidth="md"
      open={searchPopperOpen}
      scroll="body"
      fullWidth
      onClose={onSearchPopperClose}
    >
      <SearchPopperHeader
        searchQuery={searchQuery}
        onClose={onSearchPopperClose}
      >
        {userSettings !== undefined && userSettings?.useOpenAI === true ? (
          <ExplainHighlightCard docId={docId} searchQuery={searchQuery} />
        ) : undefined}
      </SearchPopperHeader>

      <SearchPopperContent searchQuery={searchQuery} />
    </Dialog>
  );
};

export default SearchWithNoteOrHighlightDialog;
