import React, { FC } from 'react';

import { Card, CardContent } from '@mui/material';

interface Props {
  content: string;
}

export const SimpleCard: FC<Props> = ({ content }) => (
  <Card elevation={9}>
    <CardContent sx={{ m: 0, maxHeight: 220, overflow: 'hidden' }}>
      {content}
    </CardContent>
  </Card>
);
