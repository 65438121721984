import React, { useState } from 'react';

import { Card, Divider } from '@mui/material';
import clsx from 'clsx';

import { isRetrievalUnitData } from '../isRetrievalUnitData';
import { useStyles } from '../styles';

import { Actions } from './Actions';
import { Content } from './Content';
import { Expand } from './Expand';
import { RetrievalUnitCardDefaultProps } from './RetrievalUnitCardDefaultProps';

export default function RetrievalUnitCardDefault({
  cardProps,
  classes: inputClasses,
  highlighted,
  ...props
}: RetrievalUnitCardDefaultProps) {
  const classes = useStyles();
  const [notesExpanded, setNotesExpanded] = useState(false);

  const isFullData = isRetrievalUnitData(props.data);

  return (
    <Card
      {...cardProps}
      aria-label="retrieval unit"
      className={clsx(
        classes.card,
        highlighted && classes.highlighted,
        inputClasses?.root
      )}
      data-testid="RetrievalUnitCardDefault"
      elevation={notesExpanded ? 10 : 1}
    >
      <Content {...props} />

      {isFullData && (
        <>
          <Divider />
          <Actions
            notesExpanded={notesExpanded}
            setNotesExpanded={setNotesExpanded}
            {...props}
          />
          <Expand
            notesExpanded={notesExpanded}
            setNotesExpanded={setNotesExpanded}
            {...props}
          />
        </>
      )}
    </Card>
  );
}
