import {
  EventSourceMessage,
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';

import { tryToGetRefreshedToken } from './axiosInstance';

enum ErrorType {
  FatalError = 'FatalError',
  RetriableError = 'RetriableError',
}

const MAX_RETRIES = 3;

interface Props<T, R> {
  abortController?: AbortController;
  onError?: (error: Error) => void;
  onFinish?: () => void;
  onMessage: (data: R) => void;
  payload: T;
  url: string;
}

export const sseRequest = async <T, R>({
  abortController,
  onError,
  onFinish,
  onMessage,
  payload,
  url,
}: Props<T, R>) => {
  const storedAccessToken = await tryToGetRefreshedToken();
  if (!storedAccessToken) {
    throw new Error('Access Token does not exist');
  }

  const config = {
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${storedAccessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    try: 0,
  };

  await fetchEventSource(url, {
    ...config,

    onclose() {
      onFinish?.();
      config.try = 0;
    },

    onerror(error: Error) {
      if (error.message?.startsWith(ErrorType.RetriableError)) {
        // In this case, we are going to retry
        return;
      }
      onError?.(error);
      throw error;
    },

    onmessage(message: EventSourceMessage) {
      if (
        message.event?.startsWith(ErrorType.FatalError) ||
        message.event === 'error'
      ) {
        throw new Error(`${ErrorType.FatalError}: ${message.data}`);
      }

      if (!message.data) {
        return;
      }

      try {
        onMessage?.(JSON.parse(message.data) as R);
      } catch (error) {
        throw new Error(
          `${ErrorType.FatalError}: ${(error as Error)?.message}`
        );
      }
    },

    async onopen(response: Response) {
      const contentType = response.headers.get('content-type');
      if (
        response.ok &&
        contentType &&
        [EventStreamContentType, 'application/octet-stream'].includes(
          contentType
        )
      ) {
        return; // everything's good
      }

      if (response.status === 401 && config.try < MAX_RETRIES) {
        const jwt = await tryToGetRefreshedToken();
        if (jwt) {
          config.try++;
          config.headers.Authorization = `Bearer ${jwt}`;
          throw new Error(ErrorType.RetriableError);
        }
      }
      config.try = 0;

      throw new Error(ErrorType.FatalError);
    },

    signal: abortController?.signal,
  });
};
