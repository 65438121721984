import React from 'react';

import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const TransparentTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxShadow: 'none',
    maxWidth: 600,
  },
});
