import React from 'react';

import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { clearLabelFromBrackets } from 'common/utils/strings.utils';

import { ReferenceTooltip } from './ReferenceTooltip';
import { TransparentTooltip } from './TransparentTooltip';

const useStyles = makeStyles({
  colorReferenceLink: {
    textDecoration: 'none',
  },
});

interface Props {
  href: string;
  label: number | string;
  title?: string;
}

export const ReferenceLink: React.FC<Props> = ({ href, label, title }) => {
  const classes = useStyles();

  const text = clearLabelFromBrackets(`${label}`);

  return (
    <TransparentTooltip
      title={<ReferenceTooltip content={title} href={href} />}
    >
      <Chip
        className={classes.colorReferenceLink}
        component="a"
        href={href}
        label={text}
        rel="noopener noreferrer"
        size="small"
        sx={{ ml: 0.5, mr: 0.5 }}
        target="_blank"
        clickable
      />
    </TransparentTooltip>
  );
};
