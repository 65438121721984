import React, { FC } from 'react';

import { Card } from '@mui/material';
import clsx from 'clsx';

import { RetrievalUnitData } from '../RetrievalUnitData.interface';
import { useStyles } from '../styles';

import { Content } from './Content';
import { RetrievalUnitCardDefaultProps } from './RetrievalUnitCardDefaultProps';

export const RetrievalUnitCardSimple: FC<
  RetrievalUnitCardDefaultProps<Partial<RetrievalUnitData>>
> = ({ cardProps, classes: inputClasses, highlighted, ...props }) => {
  const classes = useStyles();

  return (
    <Card
      {...cardProps}
      aria-label="retrieval unit"
      className={clsx(
        classes.card,
        highlighted && classes.highlighted,
        inputClasses?.root
      )}
      data-testid="RetrievalUnitCardSimple"
      elevation={1}
    >
      <Content {...props} />
    </Card>
  );
};
