import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  additionalMetadata: {
    '& > * + *': {
      marginLeft: theme.spacing(0.5),
    },
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(0.5),
  },
  card: {
    minWidth: 600,
    overflow: 'visible',
    position: 'relative',
  },
  cardCompressed: {
    overflow: 'visible',
    position: 'relative',
  },
  cardCompressedContent: {
    '&:last-child': { paddingBottom: '0rem', paddingTop: '0.5rem' },
    paddingBottom: '0.25rem',
    paddingTop: '0.25rem',
    position: 'relative',
  },
  cardCompressedNoBackground: {
    backgroundColor: theme.palette.body,
    boxShadow: 'none',
    overflow: 'visible',
    padding: '0px',
    position: 'relative',
  },
  cardContent: {
    '&:last-child': { paddingBottom: '0rem', paddingTop: '0.5rem' },
    paddingBottom: '0.25rem',
    paddingTop: '0.25rem',
    position: 'relative',
  },
  highlighted: {
    border: `1px solid ${theme.palette.secondary.light}`,
    borderLeftWidth: theme.spacing(0.75),
  },
}));
