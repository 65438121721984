import React from 'react';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from '@mui/material';

import TrackedLink from 'common/components/Links/TrackedLink/TrackedLink';

const MAX_LENGTH = 120;

type DocTitleLinkProps = {
  docId?: string;
  docTitle: string;
  linkType?: 'link' | 'pdf';
} & React.ComponentProps<typeof Link>;

const DocTitleLink = ({
  docId,
  docTitle,
  linkType = 'link',
  ...linkProps
}: DocTitleLinkProps) => {
  const truncatedTitle =
    docTitle.length > MAX_LENGTH
      ? `${docTitle.slice(0, MAX_LENGTH)}...`
      : docTitle;

  return (
    <TrackedLink
      eventProps={{ docId, href: linkProps.href, linkType }}
      sx={{ cursor: 'pointer', fontSize: '1rem' }}
      title={docTitle}
      underline="hover"
      {...linkProps}
    >
      {truncatedTitle}
      {linkType === 'pdf' && (
        <PictureAsPdfIcon
          color="primary"
          sx={{ fontSize: '1.3rem', ml: 0.5 }}
        />
      )}
    </TrackedLink>
  );
};

DocTitleLink.displayName = 'DocTitleLink';

export default DocTitleLink;
