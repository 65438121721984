import React, { FC } from 'react';

import { CardActions, Grid } from '@mui/material';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { HitType, SearchEngineEnum } from 'common/enums';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { FeedbackData } from 'containers/Feedback/Feedback.interface';
import SendFeedback from 'containers/Feedback/SendFeedback/SendFeedback';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import DocTags from 'containers/SavedDocuments/DocTags';

import DocActions from '../DocActions';
import { DocAction } from '../DocActions/DocActions.interface';
import DocFavoriteButton from '../DocFavoriteButton';
import DocNotesToggle from '../DocNotesToggle';
import DocOwner from '../DocOwner/DocOwner';
import DocOwnerFromOrg from '../DocOwnerFromOrg/DocOwnerFromOrg';
import DocShare from '../DocShare';
import DocSimilarToButton from '../DocSimilarToButton';

interface Props {
  data: RetrievalUnitData;
  feedback?: FeedbackData;
  moreActions?: DocAction[];
  notesExpanded: boolean;
  onPrivateDocDelete?: (id: string) => void;
  onPrivateDocEdit?: (data: RetrievalUnitData) => void;
  resultType?: ResultType;
  searchEngine?: SearchEngineEnum;
  searchId?: string;
  setNotesExpanded: (value: boolean) => void;
  suggestedTags?: TagDetailsBase[];
  withExplicitFeedback?: boolean;
  withSharedNotes?: boolean;
}

export const Actions: FC<Props> = ({
  data,
  feedback,
  moreActions,
  notesExpanded,
  onPrivateDocDelete,
  onPrivateDocEdit,
  resultType,
  searchEngine,
  searchId,
  setNotesExpanded,
  suggestedTags,
  withExplicitFeedback,
  withSharedNotes,
}) => {
  const { me } = useAuth();

  const {
    authors,
    document,
    document: { id: docId },
    getSimilarDocsId,
    ontologyId,
    organizeDocId,
    shareUri,
    title,
  } = data;

  const isPrivateDoc = document.type === HitType.PrivateDocument;
  const renderFeedback = !!(withExplicitFeedback && searchId && resultType);

  return (
    <CardActions
      data-testid="RetrievalUnitCardDefault-Actions"
      sx={{ py: 0.5 }}
    >
      <Grid
        alignItems="center"
        columnSpacing={1}
        justifyContent={'flex-end'}
        spacing={0}
        container
      >
        <>
          {isPrivateDoc && data.ownerUuid !== me?.sub && (
            <Grid alignContent="center" container item xs>
              <DocOwnerFromOrg ownerUuid={data.ownerUuid} />
            </Grid>
          )}

          {isPrivateDoc && data.ownerUuid === me?.sub && (
            <Grid alignContent="center" container item xs>
              <DocOwner />
            </Grid>
          )}

          {renderFeedback && (
            <Grid item>
              <SendFeedback
                initialValues={
                  feedback && {
                    feedbackScore: feedback.score,
                    feedbackText: feedback.text ?? '',
                  }
                }
                resultId={docId}
                resultType={resultType}
                searchId={searchId}
                withFeedbackMessage
              />
            </Grid>
          )}

          {getSimilarDocsId && (
            <Grid item>
              <DocSimilarToButton
                eventName={TrackEventName.FindSimilarClicked}
                eventProps={{ ontologyId }}
                getSimilarDocsId={getSimilarDocsId}
              />
            </Grid>
          )}

          <Grid item>
            <DocNotesToggle
              docId={organizeDocId}
              notesExpanded={notesExpanded}
              setNotesExpanded={setNotesExpanded}
              withSharedNotes={withSharedNotes}
            />
          </Grid>

          <Grid item>
            <DocTags
              docData={data}
              searchEngine={searchEngine}
              suggestedTags={suggestedTags}
            />
          </Grid>

          <Grid item>
            <DocFavoriteButton
              docData={data}
              resultType={resultType}
              searchEngine={searchEngine}
              searchId={searchId}
            />
          </Grid>

          {shareUri && (
            <Grid item>
              <DocShare
                docAuthors={authors}
                docId={docId}
                docTitle={title}
                eventName={TrackEventName.ShareDocumentClicked}
                resultType={resultType}
                searchId={searchId}
                shareUri={shareUri}
              />
            </Grid>
          )}

          <Grid item>
            <DocActions
              data={data}
              moreActions={moreActions}
              onPrivateDocDelete={onPrivateDocDelete}
              onPrivateDocEdit={onPrivateDocEdit}
            />
          </Grid>
        </>
      </Grid>
    </CardActions>
  );
};
