import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { FormTextField } from 'common/components/Fields/FormTextField';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

import { useInviteUserForm } from '../hooks/useInviteUserForm';

export type WithInviteUserDialogWrappedProps<T = {}> = {
  dialogOpen: boolean;
  setDialogOpen: (state: boolean) => void;
} & T;

const withInviteUserDialog = <T,>(
  WrappedComponent: React.ComponentType<WithInviteUserDialogWrappedProps<T>>
) => {
  const Component = ({ ...rest }: Omit<T, 'setDialogOpen' | 'dialogOpen'>) => {
    const { t } = useTranslation('common');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const handleClose = () => setDialogOpen(false);
    const { initialValues, onReset, onSubmit } = useInviteUserForm({
      onReset: handleClose,
      onSubmit: handleClose,
    });

    return (
      <>
        <WrappedComponent
          {...(rest as T)}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
        />

        <Dialog
          aria-label="Invite user dialog"
          data-testid="InviteUserDialog"
          maxWidth="sm"
          open={dialogOpen}
          fullWidth
          onClose={handleClose}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onReset={onReset}
            onSubmit={onSubmit}
          >
            {({ dirty, isSubmitting }) => (
              <Form>
                <DialogActionTitle onClose={handleClose}>
                  {t('inviteUser.dialog.title')}
                </DialogActionTitle>

                <DialogContent>
                  <Typography>{t('inviteUser.dialog.description')}</Typography>

                  <FormTextField
                    id="inviteUserEmail"
                    label={t('inviteUser.form.email.label')}
                    margin="normal"
                    name="email"
                    type="email"
                    autoFocus
                  />

                  <FormTextField
                    id="inviteUserFirstName"
                    label={t('inviteUser.form.name.label')}
                    margin="normal"
                    name="firstName"
                  />
                </DialogContent>

                <DialogActions>
                  <Button type="reset">{t('buttons.cancel')}</Button>

                  <SubmitButton
                    disabled={!dirty}
                    eventName={TrackEventName.InviteUserSubmitClicked}
                    isSubmitting={isSubmitting}
                  >
                    {t('inviteUser.form.submitButtonText')}
                  </SubmitButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </>
    );
  };

  Component.displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return Component;
};

export default withInviteUserDialog;
