import React, { FC } from 'react';

import { Optional } from 'common/utils/assert';
import { RetrievalUnitCardSimple } from 'containers/RetrievalUnit/RetrievalUnitCard/RetrievalUnitCardSimple';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

interface Props {
  content: Optional<string>;
  href: string;
  item: Optional<RetrievalUnitData>;
}

export const SimpleItem: FC<Props> = ({ content, href, item }) => {
  const data: Partial<RetrievalUnitData> = item ?? {};
  data.uri = item?.uri ?? href;
  data.title = item?.title ?? href;
  data.abstractContent = content ?? item?.abstractContent;

  return <RetrievalUnitCardSimple data={data} />;
};
