import React, { useMemo, useRef } from 'react';

import {
  Dialog,
  DialogContent,
  DialogProps,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { FieldCopyButton } from 'common/components/Fields/FieldCopyButton';
import { ShareEnum, ShareType } from 'common/enums';

import { TextField } from '../../TextField';

type ShareLinkDialogProps = {
  getShareLink: (shareType: ShareType) => string;
  onShare?: () => any;
  open: boolean;
  setOpen: (state: boolean) => void;
} & DialogProps;

const ShareLinkDialog = ({
  getShareLink,
  onShare,
  open,
  setOpen,
}: ShareLinkDialogProps) => {
  const { t } = useTranslation('common');
  const inputRef = useRef<HTMLInputElement>();

  const link = useMemo<string>(
    () => getShareLink(ShareEnum.Link),
    [getShareLink]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      aria-label={t('shareLink.dialog.ariaLabel')}
      data-testid="ShareLinkDialog"
      maxWidth="sm"
      open={open}
      fullWidth
      onClose={handleClose}
    >
      <DialogActionTitle onClose={handleClose}>
        {t('shareLink.dialog.title')}
      </DialogActionTitle>

      <DialogContent sx={{ overflow: 'unset' }}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FieldCopyButton inputRef={inputRef} onCopy={onShare} />
              </InputAdornment>
            ),
          }}
          inputRef={inputRef}
          label={t('shareLink.dialog.inputLabel')}
          value={link}
          fullWidth
        />
      </DialogContent>
    </Dialog>
  );
};

ShareLinkDialog.displayName = 'ShareLinkDialog';

export default ShareLinkDialog;
